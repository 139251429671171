import Stack, {
  onEntryChange,
} from '@shared/contentstack/live-preview/live-preview';
import { LeadSection } from '@src/cms-components/lead-section';
import { useCallback, useEffect, useState } from 'react';

const LeadSectionPreviewPage = (props: any) => {
  const { pageContext } = props;

  const [livePreviewData, setLivePreviewData] = useState<any>(null);

  Stack.startLivePreview(true);

  const fetchData = useCallback(async () => {
    const response = (await Stack.getEntry({
      contentTypeUid: 'lead_section_spz',
      url:
        pageContext?.url && pageContext?.url !== '/'
          ? pageContext?.url
          : '/live-preview',
    })) as any;
    const result = response[0][0];
    setLivePreviewData(result);
  }, [pageContext]);

  useEffect(() => {
    onEntryChange(() => fetchData());
  }, [fetchData]);

  return !!livePreviewData && <LeadSection {...livePreviewData} />;
};

export default LeadSectionPreviewPage;
